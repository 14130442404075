<template>
  <div class="checkpoint">
    <div class="checkMain" v-if="isFinished >= 0">
      <div class="finish" v-if="isFinished === 1">
        <div class="exit" @click="exit">退出</div>
        <div class="tip">
          <span class="player">{{ player }}</span>
        </div>
        <!-- <img src="../assets/image/other/btn_exit.png" alt="" /> -->
        <img class="badge" src="../assets/image/other/badge.png" alt="" />
      </div>
      <div v-else>
        <div class="topInfo">
          <div class="player">
            <span class="label">玩家</span>
            <div class="value">{{ player }}</div>
          </div>
          <div class="card">
            <span class="label">拥有卡牌</span>
            <div class="value">{{ cardNum }}</div>
          </div>
        </div>
        <ul class="cardItems">
          <li
            v-for="(item, index) in cardList"
            :key="index"
            :style="{
              'background-image':
                'url(' +
                (index < fmProductQuestionId || isFinished == 1
                  ? item.activeImg
                  : item.defaultImg) +
                ')','display':index ==2 ? 'none' :'inline-block'
            }"
          >
            <img
              class="succeed"
              v-if="index + 1 < fmProductQuestionId || isFinished == 1"
              src="@/assets/image/other/succeed.png"
              alt=""
            />
            <img
              class="star"
              v-if="index + 1 < fmProductQuestionId || isFinished == 1"
              src="@/assets/image/other/star1.png"
              alt=""
            />
            <img
              class="star"
              v-else
              src="@/assets/image/other/star2.png"
              alt=""
            />
          </li>
        </ul>
        <p class="tips" v-if="fmProductQuestionId == 1">
          亲爱的玩家，你准备好开始冒险了吗？
        </p>
        <div
          class="startBtn"
          @click="begin"
          v-if="isFinished == 0"
          :style="fmProductQuestionId != 1 ? 'margin-top:110px;' : ''"
        >
          {{ fmProductQuestionId == 1 ? '出发' : '继续挑战' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { getNextQues } from '@/api/index';
export default {
  data() {
    return {
      player: '',
      cardNum: 0,
      cardList: [
        {
          id: 1,
          name: '卡片找同伴',
          activeImg: require('../assets/image/other/ka3-1.png'),
          defaultImg: require('../assets/image/other/ka3-1.png'),
        },
        {
          id: 2,
          name: '脸谱大作战',
          activeImg: require('../assets/image/other/ka1-1.png'),
          defaultImg: require('../assets/image/other/ka1-2.png'),
        },
        {
          id: 3,
          name: '千万别按X',
          activeImg: require('../assets/image/other/ka2-1.png'),
          defaultImg: require('../assets/image/other/ka2-2.png'),
        },
        {
          id: 4,
          name: '图片躲猫猫',
          activeImg: require('../assets/image/other/ka4-1.png'),
          defaultImg: require('../assets/image/other/ka4-2.png'),
        },
        {
          id: 5,
          name: '镜子中的我',
          activeImg: require('../assets/image/other/ka5-1.png'),
          defaultImg: require('../assets/image/other/ka5-2.png'),
        },
      ],
      fmProductQuestionId: '',
      quesNum: '',
      isFinished: '',
      // current: {},
    };
  },
  methods: {
    //获取进度
    // getProgress() {
    //   let query = { fmUserId: this.$util.getStorage('fmUserId') };
    //   getNextQues(query)
    //     .then((res) => {
    //       if (res) {
    //         this.current = res;
    //         if (this.current.isFinished == 1) {
    //           this.cardNum = this.cardList.length;
    //         } else {
    //           this.cardNum =
    //             this.current.fmProductQuestionId - 1 >= 0
    //               ? this.current.fmProductQuestionId - 1
    //               : 0;
    //         }
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    begin() {
      this.$util.fullScreen();
      this.$router.replace('/topic');
    },
    exit() {
      sessionStorage.clear();
      this.$router.replace('/login');
    },
  },
  created() {
    this.player = this.$util.getStorage('name');
    this.fmProductQuestionId = Number(
      this.$util.getStorage('fmProductQuestionId')
    );
    this.quesNum = this.$util.getStorage('quesNum');
    this.isFinished = Number(this.$util.getStorage('isFinished'));
  },
  mounted() {
    if (this.isFinished == 1) {
      this.cardNum = this.cardList.length;
    } else {
      this.cardNum =
        this.fmProductQuestionId - 1 >= 0 ? this.fmProductQuestionId - 1 : 0;
      if(this.cardNum >= 3) {
        this.cardNum = this.cardNum-1;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1900px) {
  .checkpoint {
    text-align: center;
    width: 100%;
    min-width: 1000px;
    height: 100vh;
    background-image: url('../assets/image/other/bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    font-family: 'SYHeiBold';
    .checkMain {
      width: 1070px;
      height: 650px;
      position: relative;
      margin: 0 auto;
      top: calc((100% - 650px) / 2);
      .finish {
        padding-top: 50px;
        position: relative;
        .exit {
          width: 140px;
          height: 74px;
          line-height: 70px;
          font-size: 22px;
          color: #ffffff;
          display: inline-block;
          background-image: url('../assets/image/other/btn_exit.png');
          background-repeat: no-repeat;
          background-size: cover;
          position: absolute;
          right: 0;
          top: 0;
          cursor: pointer;
        }
        .tip {
          width: 804px;
          height: 241px;
          background-image: url('../assets/image/other/finishTip.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
          font-size: 36px;
          margin: 0 auto;
          position: relative;
          .player {
            width: 119px;
            height: 43px;
            padding: 0 12px;
            line-height: 43px;
            position: absolute;
            left: 155px;
            top: 99px;
            color: #259552;
            font-size: 28px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .badge {
          width: 194px;
          height: 259px;
        }
      }
      .topInfo {
        text-align: right;
        .player {
          vertical-align: top;
          display: inline-block;
          margin-right: 30px;
          .value {
            width: 102px;
            height: 46px;
            line-height: 46px;
            background-image: url('../assets/image/other/playerBg.png');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0 10px;
          }
        }
        .card {
          vertical-align: top;
          display: inline-block;
          .value {
            width: 46px;
            height: 46px;
            line-height: 46px;
            background-image: url('../assets/image/other/cardNumBg.png');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
        .label {
          vertical-align: top;
          font-size: 20px;
          line-height: 46px;
          color: #259552;
          text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
        }
        .value {
          display: inline-block;
          color: #7a3398;
          font-size: 20px;
          text-align: center;
        }
      }
      .cardItems {
        display: flex;
        margin: 120px auto 0 auto;
        li {
          width: 192px;
          height: 252px;
          margin: 10px 30px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          // background: #cecece;
          // border: 6px solid #ffffff;
          // border-radius: 20px;
          display: inline-block;
          position: relative;
          .succeed {
            width: 120px;
            height: 96px;
            position: absolute;
            top: -30px;
            left: -30px;
          }
          .imgBox {
            width: 168px;
            height: 150px;
            background: #ffffff;
            border-radius: 16px;
            margin: 6px auto;
          }
          .name {
            color: #a1a1a1;
            font-size: 24px;
            line-height: 42px;
            text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
          }
          .star {
            width: 144px;
            height: 46px;
            position: absolute;
            left: 50%;
            bottom: -30px;
            margin-left: -72px;
          }
        }
        // li.active {
        //   background: #2dac60;
        //   .name {
        //     color: #259552;
        //   }
        // }
      }
      .tips {
        margin-top: 86px;
        font-size: 24px;
        color: #259552;
        text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
      }
      .startBtn {
        width: 216px;
        height: 84px;
        line-height: 65px;
        text-align: center;
        display: inline-block;
        background-image: url('../assets/image/other/btn.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        font-size: 30px;
        color: #ffffff;
        margin-top: 34px;
        cursor: url('http://upload.jahr.janjk.com/hand.ico'), pointer;
      }
    }
  }
}
@media screen and (min-width: 1900px) {
  .checkpoint {
    text-align: center;
    width: 100%;
    min-width: 1300px;
    height: 100vh;
    background-image: url('../assets/image/other/bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    font-family: 'SYHeiBold';
    .checkMain {
      width: 1391px;
      height: 845px;
      position: relative;
      margin: 0 auto;
      top: calc((100% - 845px) / 2);
      .finish {
        padding-top: 65px;
        .exit {
          width: 182px;
          height: 96px;
          line-height: 91px;
          font-size: 28px;
          color: #ffffff;
          display: inline-block;
          background-image: url('../assets/image/other/btn_exit.png');
          background-repeat: no-repeat;
          background-size: cover;
          position: absolute;
          right: 0;
          top: 0;
          cursor: pointer;
        }
        .tip {
          width: 1045px;
          height: 313px;
          background-image: url('../assets/image/other/finishTip.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
          font-size: 47px;
          margin: 0 auto;
          position: relative;
          .player {
            width: 155px;
            height: 56px;
            padding: 0 16px;
            line-height: 56px;
            position: absolute;
            left: 201px;
            top: 129px;
            color: #259552;
            font-size: 36.5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .badge {
          width: 252px;
          height: 337px;
        }
      }
      .topInfo {
        text-align: right;
        .player {
          vertical-align: top;
          display: inline-block;
          margin-right: 39px;
          .value {
            width: 143px;
            height: 60px;
            line-height: 60px;
            background-image: url('../assets/image/other/playerBg.png');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0 13px;
          }
        }
        .card {
          vertical-align: top;
          display: inline-block;
          .value {
            width: 60px;
            height: 60px;
            line-height: 60px;
            background-image: url('../assets/image/other/cardNumBg.png');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
        .label {
          vertical-align: top;
          font-size: 26px;
          line-height: 60px;
          color: #259552;
          text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
        }
        .value {
          display: inline-block;
          color: #7a3398;
          font-size: 26px;
          text-align: center;
        }
      }
      .cardItems {
        display: flex;
        margin: 120px auto 0 auto;
        li {
          width: 250px;
          height: 328px;
          margin: 13px 39px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          display: inline-block;
          position: relative;
          .succeed {
            width: 156px;
            height: 125px;
            position: absolute;
            top: -39px;
            left: -39px;
          }
          .imgBox {
            width: 218px;
            height: 195px;
            background: #ffffff;
            border-radius: 20px;
            margin: 8px auto;
          }
          .name {
            color: #a1a1a1;
            font-size: 31px;
            line-height: 55px;
            text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
          }
          .star {
            width: 187px;
            height: 60px;
            position: absolute;
            left: 50%;
            bottom: -39px;
            margin-left: -94px;
          }
        }
      }
      .tips {
        margin-top: 112px;
        font-size: 31px;
        color: #259552;
        text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
      }
      .startBtn {
        width: 281px;
        height: 109px;
        line-height: 84.5px;
        text-align: center;
        display: inline-block;
        background-image: url('../assets/image/other/btn.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        font-size: 39px;
        color: #ffffff;
        margin-top: 44px;
        cursor: url('http://upload.jahr.janjk.com/hand.ico'), pointer;
      }
    }
  }
}
</style>
