var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"checkpoint"},[(_vm.isFinished >= 0)?_c('div',{staticClass:"checkMain"},[(_vm.isFinished === 1)?_c('div',{staticClass:"finish"},[_c('div',{staticClass:"exit",on:{"click":_vm.exit}},[_vm._v("退出")]),_c('div',{staticClass:"tip"},[_c('span',{staticClass:"player"},[_vm._v(_vm._s(_vm.player))])]),_c('img',{staticClass:"badge",attrs:{"src":require("../assets/image/other/badge.png"),"alt":""}})]):_c('div',[_c('div',{staticClass:"topInfo"},[_c('div',{staticClass:"player"},[_c('span',{staticClass:"label"},[_vm._v("玩家")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.player))])]),_c('div',{staticClass:"card"},[_c('span',{staticClass:"label"},[_vm._v("拥有卡牌")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.cardNum))])])]),_c('ul',{staticClass:"cardItems"},_vm._l((_vm.cardList),function(item,index){return _c('li',{key:index,style:({
            'background-image':
              'url(' +
              (index < _vm.fmProductQuestionId || _vm.isFinished == 1
                ? item.activeImg
                : item.defaultImg) +
              ')','display':index ==2 ? 'none' :'inline-block'
          })},[(index + 1 < _vm.fmProductQuestionId || _vm.isFinished == 1)?_c('img',{staticClass:"succeed",attrs:{"src":require("@/assets/image/other/succeed.png"),"alt":""}}):_vm._e(),(index + 1 < _vm.fmProductQuestionId || _vm.isFinished == 1)?_c('img',{staticClass:"star",attrs:{"src":require("@/assets/image/other/star1.png"),"alt":""}}):_c('img',{staticClass:"star",attrs:{"src":require("@/assets/image/other/star2.png"),"alt":""}})])}),0),(_vm.fmProductQuestionId == 1)?_c('p',{staticClass:"tips"},[_vm._v(" 亲爱的玩家，你准备好开始冒险了吗？ ")]):_vm._e(),(_vm.isFinished == 0)?_c('div',{staticClass:"startBtn",style:(_vm.fmProductQuestionId != 1 ? 'margin-top:110px;' : ''),on:{"click":_vm.begin}},[_vm._v(" "+_vm._s(_vm.fmProductQuestionId == 1 ? '出发' : '继续挑战')+" ")]):_vm._e()])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }